<template>
  <div class="account-container">
    <div class="account-form">
      <form @submit.prevent="submitForm">
        <!-- Conta -->
        <div class="form-group">
          <v-select
            :options="accountNames"
            v-model="formData.name"
            label="name"
            class="input-field"
            placeholder="Escolha sua conta"
            taggable
            :no-options-text="'Digite para criar uma nova conta'"
            @tag="addNewAccount"
            @change="updateSelectedDeposits">
          </v-select>
        </div>
        <!-- Checkbox Limitada -->
        <div class="checkbox-group">
          <input type="checkbox" 
                 id="limited" 
                 v-model="formData.limited" 
                 class="checkbox"/>
          <label for="limited" class="checkbox-label">Limitada</label>
        </div>
        <!-- Checkbox Desativada -->
        <div class="checkbox-group" v-if="existingAccount">
          <input type="checkbox" 
                 id="disabled" 
                 v-model="formData.disabled" 
                 class="checkbox"/>
          <label for="disabled" class="checkbox-label">Desativada</label>
        </div>
        <!-- Valor do Depósito -->
        <div class="form-group">
          <input type="number" 
                id="depositValue" 
                v-model="formData.value" 
                :placeholder="formData.value ? '' : 'Valor do Depósito'" 
                @focus="valueFocused = true" 
                @blur="handleBlur" 
                class="input-field"/>
          <label for="depositValue" 
                :class="{ 'float-label': valueFocused || formData.value }">
          </label>
        </div>
        <!-- Data do Depósito -->
        <div class="form-group">
          <input 
            type="text" 
            id="depositDate" 
            v-model="formData.date" 
            @focus="toggleDateInput('date')" 
            @blur="toggleDateInput('text')" 
            class="input-field" 
            placeholder="Data do Depósito"
          />
          <label for="depositDate" :class="{ 'float-label': formData.date }">
          </label>
        </div>
        <div class="button-container">
          <button type="submit" class="save-button">{{ submitButtonText }}</button>
          <button v-if="existingAccount" @click.prevent="deleteSelectedAccount" class="delete-button">Deletar</button>
        </div>

      </form>
    </div>
    <div v-if="errorMessage" class="error-message">
      {{ errorMessage }}
    </div>
    <div class="deposit-list" v-if="selectedDeposits.length > 0">
      <h3>Últimos cinco depósitos:</h3>
      <ul>
        <li v-for="deposit in selectedDeposits" :key="deposit.created_at">
          {{ formatCurrency(deposit.amount) }} - Dia: {{ deposit.date }}
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import 'vue3-select/dist/vue3-select.css';
import VSelect from 'vue3-select';
import { useToast } from 'vue-toastification';
import { createBettingAccount, updateBettingAccount, deleteBettingAccount } from '@/services/bettingService';

export default {
  components: {
    'v-select': VSelect,
  },
  props: ['accountNames'],
  data() {
    return {
      formData: {
        name: '',
        limited: false,
        disabled: false,
        value: null,
        date: '',
      },
      valueFocused: false,
      submitButtonText: 'Salvar',
      existingAccount: false,
      errorMessage: '',
      selectedDeposits: [],
    };
  },
  setup() {
    const toast = useToast();
    return { toast };
  },
  watch: {
    'formData.name': function (newVal) {
      this.updateAccountStatus(newVal);
    },
  },
  methods: {
    async submitForm() {
      console.log('submitForm called');
      if (!this.formData.name) {
        this.setErrorMessage('Preencha o nome da conta');
        return;
      }
      try {
        if (this.existingAccount) {
          console.log('Calling updateAccount');
          await this.updateAccount();
        } else {
          console.log('Calling createAccount');
          await this.createAccount();
        }
        this.fetchAccounts();
      } catch (error) {
        console.error('Erro ao submeter o formulário:', error);
        this.toast.error('Erro ao submeter o formulário.');
      }
    },
    setErrorMessage(message) {
      this.errorMessage = message;
      if (message) {
        this.toast.error(message);
      }
    },
    async createAccount() {
      try {
        const data = await createBettingAccount(this.getRequestData());
        this.handleAccountResponse(data);
        this.fetchAccounts();
        this.setErrorMessage('');  // Clear any previous error message
        this.toast.success('Conta criada com sucesso!');
      } catch (error) {
        this.handleCreateAccountError(error);
      }
    },
    async updateAccount() {
      const selectedAccount = this.getSelectedAccount();
      if (!selectedAccount) {
        this.setErrorMessage("Conta não encontrada para atualização.");
        return;
      }
      try {
        await updateBettingAccount(selectedAccount.id, this.getRequestData());
        this.fetchAccounts();
        this.resetFormData();
        this.setErrorMessage('');  // Clear any previous error message
        this.toast.success('Conta atualizada com sucesso!');
      } catch (error) {
        console.error('Error updating account:', error);
        this.setErrorMessage('Falha ao atualizar a conta. Por favor, tente novamente.');
      }
    },
    async deleteSelectedAccount() {
      const selectedAccount = this.getSelectedAccount();
      if (!selectedAccount) {
        this.setErrorMessage("Conta não encontrada.");
        return;
      }
      try {
        await deleteBettingAccount(selectedAccount.id);
        this.fetchAccounts();
        this.resetFormData();
        this.setErrorMessage('');  // Clear any previous error message
        this.selectedDeposits = [];
        this.toast.success('Conta deletada com sucesso!');
      } catch (error) {
        console.error('Error deleting account:', error);
        this.setErrorMessage('Falha ao deletar a conta. Por favor, tente novamente.');
      }
    },
    getSelectedAccount() {
      const selectedAccountName = this.formData.name?.name || this.formData.name || '';
      return this.accountNames.find(account => account.name === selectedAccountName);
    },
    getRequestData() {
      const nameValue = typeof this.formData.name === 'object' ? this.formData.name.name : this.formData.name;
      return {
        user: localStorage.getItem('username'), 
        deposit_amount: this.formData.value,
        deposit_date: this.formData.date,
        name: nameValue,
        limited: this.formData.limited,
        disabled: this.formData.disabled,
      };
    },
    handleAccountResponse(data) {
      this.selectedDeposits.push(data);
      this.resetFormData();
      this.setErrorMessage('');
    },
    handleCreateAccountError(error) {
      if (error.response && error.response.status === 400) {
        this.setErrorMessage('Já existe uma conta com esse nome');
      } else {
        this.toast.error('Erro ao criar a conta.');
      }
    },
    fetchAccounts() {
      this.$emit('account-changed');
    },
    updateAccountStatus(newVal) {
      this.updateSelectedDeposits(newVal);
    },
    updateSelectedDeposits() {
      const selectedAccount = this.getSelectedAccount();
      this.submitButtonText = selectedAccount ? 'Atualizar' : 'Salvar';
      if (selectedAccount && selectedAccount.deposits) {
        this.existingAccount = true;
        this.selectedDeposits = selectedAccount.deposits;
        this.formData.limited = selectedAccount.limited;
        this.formData.disabled = !selectedAccount.active;  // Disable the form if the account is not active
      } else {
        this.existingAccount = false;
        this.selectedDeposits = [];
        this.formData.limited = false;
        this.formData.disabled = false;
      }
    },
    formatCurrency(amount) {
      return new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(amount);
    },
    toggleDateInput(type) {
      if (type === 'date') {
        const date = new Date();
        const formattedDate = `${this.padTo2Digits(date.getDate())}-${this.padTo2Digits(date.getMonth() + 1)}-${date.getFullYear()}`;
        this.formData.date = formattedDate;
      }
    },
    padTo2Digits(num) {
      return num.toString().padStart(2, '0');
    },
    handleBlur() {
      this.valueFocused = false;
      this.formatCurrency();
    },
    addNewAccount(newAccountName) {
      this.$emit('account-changed', newAccountName);
    },
    resetFormData() {
      Object.assign(this.formData, {
        name: '',
        limited: false,
        disabled: false,
        value: null,
        date: ''
      });
      this.submitButtonText = 'Salvar';
    },
  },
};
</script>





<style scoped>
/* Importando a fonte Roboto */
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');

/* Estilos do formulário */
.account-container {
  background-color: #2b2b2b; /* Cor de fundo */
  border-radius: 10px; /* Bordas arredondadas */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Sombra */
  padding: 20px;
  margin: 20px;
  max-height: 100vh;
  font-size: 1rem;
  color: #e0e0e0; /* Texto claro */
  overflow: hidden;
  font-family: 'Roboto', sans-serif; /* Fonte Roboto */
}

.account-form {
  background-color: #3a3a3a; /* Cor de fundo do formulário */
  padding: 20px;
  border-radius: 10px; /* Bordas arredondadas */
  margin-bottom: 20px;
}

.form-group {
  margin-bottom: 20px; /* Espaçamento entre os campos */
  display: flex; /* Alinhamento horizontal */
  flex-direction: column; /* Alinhamento vertical */
}

.form-group label {
  color: #b0b0b0; /* Cor do texto dos labels */
  margin-bottom: 5px;
  font-weight: 500; /* Peso da fonte */
}

/* Estilos para os campos de entrada */
.form-group input,
.form-group select,
.form-group textarea {
  background-color: #f5f5f5; /* Cor de fundo */
  border: 1px solid #ccc; /* Cor da borda */
  border-radius: 5px; /* Bordas arredondadas */
  padding: 10px;
  font-size: 1rem;
  color: #333; /* Cor do texto */
  transition: all 0.3s ease;
  width: 100%;
  box-sizing: border-box;
}

.form-group input:focus {
  outline: none;
  box-shadow: 0 0 5px 1px #007bff; /* Sombra ao focar */
}

/* Estilos para o botão Salvar */
.save-button {
  background-color: #007bff; /* Cor de fundo */
  color: white;
  padding: 12px 20px;
  border: none;
  border-radius: 6px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  font-size: 1rem;
  font-weight: bold;
  text-align: center;
  transition: all 0.3s ease;
  margin-top: 10px;
}


.save-button:hover {
  background-color: #0056b3; /* Cor ao passar o mouse */
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
  transform: translateY(-2px); /* Efeito de elevação ao passar o mouse */
}

.save-button:active {
  background-color: #004085; /* Cor ao clicar */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transform: translateY(0); /* Reduz o efeito de elevação ao clicar */
}

/* Estilos para a lista de depósitos */
.deposit-list {
  background-color: #3a3a3a;
  padding: 20px;
  border-radius: 10px;
  margin-top: 20px;
}

.deposit-list ul {
  list-style-type: none;
  padding: 0;
}

.deposit-list li {
  background-color: #4a4a4a;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
  color: #e0e0e0;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
}

.checkbox-group {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.checkbox {
  margin: 0;
}

.checkbox-label {
  margin-left: 10px; /* Espaço entre o checkbox e o label */
  color: #b0b0b0; /* Cor do texto */
}

.error-message {
  color: #ff3860; /* Cor do texto */
  background-color: #ffe5e5; /* Cor de fundo */
  border: 1px solid #ff3860; /* Cor da borda */
  border-radius: 5px; /* Bordas arredondadas */
  padding: 10px;
  margin: 20px 0;
  text-align: center;
  font-weight: bold;
  font-size: 0.9rem;
}

.input-field.vs--single.vs--searchable {
  background-color: #f5f5f5;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 1rem;
  color: #333;
  transition: all 0.3s ease;
  width: 100%;
  box-sizing: border-box;
}

.input-field .vs__search {
  background-color: transparent;
  color: #333;
}

.input-field .vs__selected {
  color: #333;
}

.input-field .vs__clear,
.input-field .vs__open-indicator {
  fill: #888;
}

.input-field .vs__dropdown-toggle:hover .vs__open-indicator,
.input-field .vs__dropdown-toggle:hover .vs__clear {
  fill: #555;
}

.input-field .vs__dropdown-toggle:active .vs__open-indicator,
.input-field .vs__dropdown-toggle:active .vs__clear {
  fill: #333;
}

.delete-button {
  background-color: #dc3545; /* Cor de fundo */
  color: white;
  padding: 12px 20px;
  border: none;
  border-radius: 6px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  font-size: 1rem;
  font-weight: bold;
  text-align: center;
  transition: all 0.3s ease;
  margin-top: 10px;
}


.delete-button:hover {
  background-color: #c82333; /* Cor ao passar o mouse */
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
  transform: translateY(-2px); /* Efeito de elevação ao passar o mouse */
}

.delete-button:active {
  background-color: #bd2130; /* Cor ao clicar */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transform: translateY(0); /* Reduz o efeito de elevação ao clicar */
}
.button-container {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}
.button-container .save-button, .button-container .delete-button {
  flex: 1;
  margin-right: 5px;
}

.button-container .delete-button {
  margin-right: 0;
}

h3 {
  margin-bottom: 10px;
  color: #e0e0e0;
}
</style>

<template>
  <div class="auth-container">
    <h1>Registre-se</h1>
    <form @submit.prevent="handleRegister">
      <div class="form-group">
        <input id="username" v-model="username" required class="form-control" placeholder="Usuário" />
      </div>
      <div class="form-group">
        <input id="email" type="email" v-model="email" required class="form-control" placeholder="E-mail" />
      </div>
      <div class="form-group">
        <input
          id="phone_number"
          v-model="phone_number"
          required
          class="form-control"
          placeholder="Telefone"
          v-imask="{ mask: '(00) 00000-0000' }"
        />
      </div>
      <div class="form-group">
        <input id="password" type="password" v-model="password" required class="form-control" placeholder="Senha" />
      </div>
      <div class="form-group">
        <input id="confirmPassword" type="password" v-model="confirmPassword" required class="form-control" placeholder="Confirmar Senha" />
      </div>
      <button type="submit" class="btn-auth">Registrar</button>
    </form>
    <div v-if="registrationMessage" class="registration-message">{{ registrationMessage }}</div>
    <div class="switch-auth">
      Já tem uma conta? <router-link class="router-link" to="/login">Entrar</router-link>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue';
import { IMaskDirective } from 'vue-imask';

export default {
  name: 'UserRegister',
  directives: {
    imask: IMaskDirective,
  },
  setup() {
    const username = ref('');
    const email = ref('');
    const phone_number = ref('');
    const password = ref('');
    const confirmPassword = ref('');
    const registrationMessage = ref('');

    const handleRegister = async () => {
      if (password.value && password.value === confirmPassword.value) {
        try {
          const response = await fetch('https://api.dalemilionario.com/api/v1/register/', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({
              username: username.value,
              email: email.value,
              phone_number: phone_number.value,
              password: password.value,
            })
          });

          if (!response.ok) {
            throw new Error('Erro ao registrar o usuário');
          }

          registrationMessage.value = 'Usuário cadastrado, ficará inativo até o administrador do site ativar seu registro.';

          window.open(`https://t.me/new_dale_bot`, '_blank');
          
        } catch (error) {
          console.error('Erro ao registrar o usuário:', error);
          registrationMessage.value = 'Erro ao tentar registrar. Tente novamente mais tarde.';
        }
      } else {
        console.error('As senhas não coincidem.');
        registrationMessage.value = 'As senhas não coincidem. Por favor, verifique os campos de senha.';
      }
    };

    return {
      username,
      email,
      phone_number,
      password,
      confirmPassword,
      handleRegister,
      registrationMessage
    };
  }
};
</script>

<style scoped>
.auth-container {
  max-width: 400px;
  margin: 5% auto;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  background-color: #282828;
  color: #ccc;
}

h1 {
  color: #ccc;
  text-align: center;
  margin-bottom: 20px;
  font-size: 1.8rem;
}

.form-group {
  margin-bottom: 20px;
}

.form-control {
  width: 100%;
  padding: 15px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #fff;
  color: #333;
  font-size: 1rem;
}

.form-control::placeholder {
  color: #888;
  opacity: 1;
  font-size: 1rem;
}

.btn-auth {
  width: 100%;
  padding: 10px;
  background-color: #1abc9c;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 10px;
  font-weight: bold;
  font-size: 1rem;
}

.btn-auth:hover {
  background-color: #16a085;
}

.switch-auth {
  text-align: center;
  margin-top: 15px;
}

.router-link {
  text-decoration: none;
}

.router-link:hover {
  text-decoration: underline;
}

.registration-message {
  margin-top: 20px;
  color: #ccc;
  font-size: 1.4rem;
}
</style>

<template>
  <header class="header-style">
    <div class="flex-container">
      <div class="brand">
        <span class="brand-dale">dale</span><span class="brand-365">GT</span>
      </div>
      <nav class="navigation">
        <router-link to="/partidas" class="nav-link" :class="{ 'active': $route.path === '/partidas' }">Partidas</router-link>
        <router-link to="/ao-vivo" class="nav-link" :class="{ 'active': $route.path === '/ao-vivo' }">Ao-Vivo</router-link>
        <router-link to="/apostas" class="nav-link" :class="{ 'active': $route.path === '/apostas' }">Minhas Apostas</router-link>
      </nav>
      <div class="user-info">
        <router-link to="/conta" class="user-icon" :class="{ 'active': $route.path === '/conta' }"></router-link>
        <div class="balance-and-name">
          <span class="user-balance">{{ formattedUserBalance }}</span>
          <span class="user-name">{{ userName }}</span>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import { getActiveAccountsBalance } from '@/services/bettingService';

export default {
  name: 'DaleHeader',
  data() {
    return {
      balance: 'R$ 0,00',
      userName: localStorage.getItem('username') || ''
    };
  },
  computed: {
    formattedUserBalance() {
      return this.balance;
    }
  },
  methods: {
    async fetchUserBalance() {
      try {
        const response = await getActiveAccountsBalance();
        this.balance = `R$ ${response.total_balance.toFixed(2).replace('.', ',')}`;
      } catch (error) {
        console.error('Erro ao buscar o saldo do usuário:', error);
      }
    }
  },
  mounted() {
    this.fetchUserBalance();
    setInterval(this.fetchUserBalance, 3600000); // 1 hora = 3600000 ms
  },
  watch: {
    userBalance(newBalance) {
      this.balance = newBalance;
    }
  }
};
</script>

<style scoped>
.header-style {
  background-color: #126e51;
  color: #ddd;
  padding: 0.3rem;
  padding-left: 2rem;
  padding-right: 2rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
  font-family: 'Roboto', sans-serif; /* Aplica a fonte Roboto */
}

.flex-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.brand {
  font-size: 1.5rem;
  font-weight: bold;
}

.brand-dale {
  color: #fff;
}

.brand-365 {
  color: #ffd700;
}

.navigation {
  display: flex;
  font-size: 0.9rem;
  flex-grow: 1;
  justify-content: center;
}

.nav-link {
  color: #ddd;
  text-decoration: none;
  padding: 5px 10px;
  position: relative;
}

.nav-link.active::after {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  bottom: -18px;
  height: 2px;
  background-color: #ffdf1b;
  opacity: 0.8;
}

.user-info {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.balance-and-name {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 0.5rem;
}

.user-balance {
  color: #FFFFFF;
  font-size: 0.7rem;
}

.user-name {
  color: #26ffbe;
  font-size: 0.6em;
}

.user-icon {
  background-image: url('~@/assets/user-icon.svg');
  background-size: contain;
  background-repeat: no-repeat;
  width: 2.5vh; /* Tamanho reduzido do ícone */
  height: 3vh; /* Tamanho reduzido do ícone */
  display: inline-block;
  cursor: pointer;
  margin-left: 0.5rem;
}

.logout {
  cursor: pointer;
  padding: 10px;
  background-color: red;
  color: white;
  border-radius: 5px;
  margin-top: 5px;
}

@media (max-width: 768px) {
  .flex-container {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .brand {
    order: 1;
    flex-grow: 1;
    text-align: center;
  }

  .navigation {
    order: 2;
    flex-direction: row;
    flex-grow: 1;
    font-size: 0.7rem;
  }

  .user-info {
    order: 3;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: auto;
  }

  .user-icon {
    width: 2vh; /* Tamanho reduzido do ícone */
    height: 2vh; /* Tamanho reduzido do ícone */
    margin-bottom: 0.3rem;
  }

  .balance-and-name {
    flex-direction: column;
    align-items: center;
  }

  .user-balance, .user-name {
    font-size: 0.6rem; /* Tamanho de fonte reduzido */
    text-align: center;
  }

  .header-style {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .nav-link.active::after{
    bottom: -17px;
  }
}
</style>

const API_BASE_URL = 'https://api.dalemilionario.com/api/v1';

async function fetchFromApi(endpoint, options = {}) {
  const url = `${API_BASE_URL}${endpoint}`;
  const defaultHeaders = {
    'Content-Type': 'application/json',
    'Authorization': `Token ${localStorage.getItem('userToken')}`
  };

  const config = {
    ...options,
    headers: {
      ...defaultHeaders,
      ...options.headers
    }
  };

  try {
    const response = await fetch(url, config);
    if (response.status === 401) {
      const data = await response.json();
      if (data.detail === 'Invalid token.') {
        // Deslogar usuário e redirecionar para a página de login
        localStorage.removeItem('userToken');
        localStorage.setItem('userLoggedIn', 'false');
        window.location.href = '/login';
      }
    }
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    return await response.json();
  } catch (error) {
    console.error('Erro ao fazer requisição para a API:', error);
    throw error;
  }
}

export function getDailySummary(month, year) {
  return fetchFromApi(`/accounts/daily-summary/?month=${month}&year=${year}`);
}

export function getMonthlyWithdrawals(year, month) {
  return fetchFromApi(`/accounts/monthly-withdrawals/?year=${year}&month=${month}`);
}

export function getAnnualDeposits(year) {
  return fetchFromApi(`/accounts/annual-deposits-total/?year=${year}`);
}

export function getUserBalanceRanking() {
  return fetchFromApi(`/accounts/user-balance-ranking/`);
}

export function getDailyBalances(month, year, groupByAccount) {
  const params = new URLSearchParams({ month, year, group_by_account: groupByAccount });
  return fetchFromApi(`/accounts/daily-balances/?${params.toString()}`);
}

export function getWithdrawBalances(month, year, groupByAccount) {
  const params = new URLSearchParams({ month, year, group_by_account: groupByAccount });
  return fetchFromApi(`/accounts/monthly-summary/?${params.toString()}`);
} 
